import React, { useContext } from 'react'
import { graphql, Link } from 'gatsby'
import { openZendesk } from '../helpers/forcite'
import { handleLink } from '../helpers/general'
import { get } from 'lodash';
import Layout from '../components/organisms/Layout/Layout'
import Container from '../components/atoms/Container/Container'
import PageTitle from '../components/atoms/PageTitle/PageTitle'
import Seo from '../components/organisms/Seo/Seo'
import Breadcrumbs from '../components/atoms/Breadcrumbs/Breadcrumbs'
import Icon from '../components/atoms/Icon/Icon'
import Button from '../components/atoms/Button/Button'

import * as styles from './support.module.css'
import GlobalContent from '../components/atoms/GlobalContent/GlobalContent'
import ContentfulContext from '../context/ContentfulProvider';
import ContentfulTranslationText from '../components/atoms/ContentfulTranslationText/ContentfulTranslationText';

const Support = ({data}) => {
    const contentful = useContext(ContentfulContext);
    const translations = get(contentful, 'translationData', {});

    // List categories managed in Zendesk
    const categories = data.categories.nodes.map(category => {
        // Group articles to selected sections for the category
        const sections = data.sections.nodes.filter(section => section.category_id === category.category_id).map(section => {
            return {
                id: section.section_id,
                title: section.name,
                articles: data.articles.nodes.filter(article => article.section_id === section.section_id)
            }
        });

        return {
            id: category.category_id,
            title: category.name,
            sections
        }
    });

    const assocIcons = {
        "Sales/Product Enquiries": <Icon symbol="people" />,
        "Controller": <Icon symbol="controller" />,
        "Helmet": <Icon symbol="helmet" />,
        "App": <Icon symbol="mobile" />,
        "Video and Audio": <Icon symbol="play" />,
    }
    
    return (
        <Layout>
            <Seo title="Support" />
            <Container size="medium">
                <Breadcrumbs crumbs={[{label: get(translations, 'support.title', 'Support')}]} />
                <PageTitle title={get(translations, 'support.title', 'Support')} />
                {categories.map(category => {
                    if (category.title === 'Guides') {
                        return (
                            <div key={category.title} className={styles.pageSection}>
                                <h2 className='h6'>{category.title}</h2>
                                {category.sections.map((section, sectionIndex) => (
                                    <div key={`section-${sectionIndex}`}>
                                        <span className={styles.sectionTitle}>{section.title}</span>
                                        <ul className={styles.guideList}>
                                            {section.articles.map((article, articleIndex) => {
                                                const path = handleLink(`/support/${article.title.trim().toLowerCase().replace(/[^a-zA-Z0-9\s]/g, "").replace(/[^a-zA-Z0-9]/g, "-")}/`);
                                                
                                                return <li key={`article-${articleIndex}`}><Link to={path}>{article.title}</Link></li>
                                            })}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        )
                    }

                    if (category.title === 'Frequently Asked Questions') {
                        return (
                            <div key={category.title} className={styles.pageSection}>
                                <h2 className='h6'><ContentfulTranslationText keyName="faq">FAQs</ContentfulTranslationText></h2>
                                <div className={styles.faqSections}>
                                    {category.sections.map((section, sectionIndex) => {
                                        const path = handleLink(`/support/faqs/${section.title.trim().toLowerCase().replace(/[^a-zA-Z0-9\s]/g, "").replace(/[^a-zA-Z0-9]/g, "-")}/`);
                                        return (
                                            <div key={`section-${sectionIndex}`}>
                                                {assocIcons[section.title.trim()] ? assocIcons[section.title.trim()] : (<svg width="50" height="50"><ContentfulTranslationText keyName="notFound">not found</ContentfulTranslationText></svg>)}
                                                <span className={styles.sectionTitle}>{section.title}</span>
                                                <Link to={path} className='lightLink fancy'><ContentfulTranslationText keyName="seeAll">See all</ContentfulTranslationText> {section.articles.length} <ContentfulTranslationText keyName="questions">questions</ContentfulTranslationText></Link>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }

                    return null
                })}
                <div className={`${styles.pageSection} ${styles.getInContact}`}>
                    <div className={styles.contactBody}><GlobalContent name={`Support Get In Contact`} /></div>
                    <div className={`${styles.contactButton}`}>
                        <Button type="button" level="primary" onClick={() => openZendesk()}><ContentfulTranslationText keyName="getInContact">Get in contact</ContentfulTranslationText></Button>
                    </div>
                </div>
            </Container>
        </Layout>
    );
}

export const query = graphql`
  query SupportQuery {
    categories: allZenDeskHelpDeskCategories {
        nodes {
            category_id
            name
        }
      }
    sections: allZenDeskHelpDeskSections(
        sort: {fields: position, order: ASC}
      ) {
        nodes {
            category_id
            section_id
            name
        }
      }
    articles: allZenDeskHelpDeskArticles {
        nodes {
            article_id
            section_id
            title
        }
      }
  }
`
 
export default Support;